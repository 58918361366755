import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import Img from 'gatsby-image/withIEPolyfill';

const DisplaySection: React.SFC<{}> = () => {
  const {
    jubalFeatureOne,
    jubalFeatureTwo,
    jubalFeatureThree
  } = useStaticQuery(
    graphql`
    query DisplaySectionComponentQuery {
      jubalFeatureOne: file(relativePath: { eq: "03 Jubal Hornline 2018.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        jubalFeatureTwo: file(relativePath: { eq: "ColorGuard 2018-04.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        jubalFeatureThree: file(relativePath: { eq: "Jubal Hornline 2018-warming up.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  );
  return (
    <section className="bg-superdark shadow">
      <div className="container-xl row mx-auto px-0">
        <div className={`col-12 col-md-6`}>
          <Img
            className={`mx-3 glow`}
            fluid={jubalFeatureOne.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
        <div className={`col-12 col-md-6`}>
          <Img
            className={`mx-3 mt-5 mt-md-0 glow`}
            fluid={jubalFeatureTwo.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
        <div className={`col-12`}>
          <Img
            className={`mx-3 mt-5 glow`}
            fluid={jubalFeatureThree.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
      </div>
    </section>
  );
}

export default DisplaySection;
