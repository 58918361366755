import { graphql, Link } from 'gatsby';
import * as React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './index.module.scss';
import Layout from '../components/layout';
import { EventNode } from '../templates/facebookEvent';
import EventsList from '../components/events-list';
import FacebookPostList from '../components/facebook-post-list';
import DisplaySection from '../components/section-display';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import SEO from '../components/seo';
import ArrowIcon from '../components/arrow-icon';
import SocialIcons from '../components/social-icons';

interface IndexPageProps {
  location: Location,
  data: {
    site: {
      siteMetadata: {
        tagline: string;
        contactFormUrl: string;
      },
    },
    allProgramsdataJson: {
      edges: ({
        node: ({
          id: string,
          anchor: string,
          name: string,
          logo: any,
          image: any,
          featured: boolean,
          listed: boolean,
          description: string[]
        })
      })[]
    },
    allFacebookEvents: {
      edges: ({node: EventNode})[]
    }
    flitsenBannerImage: any,
    jubalHistoryImage: any,
    jubalMoreImage: any,
    jubalFeatureOne: any,
    jubalFeatureTwo: any,
    jubalFeatureThree: any
  };
}

export const indexPageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        tagline
        contactFormUrl
      }
    }
    allProgramsdataJson {
      edges {
        node {
          id
          anchor
          name
          logo {
            childImageSharp {
              fluid(quality: 100) {
                src
              }
            }
          }
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          featured
          listed
          description
        }
      }
    }
    flitsenBannerImage: file(relativePath: { eq: "banner-flitsen-2023-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1110, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    jubalHistoryImage: file(relativePath: { eq: "06 Jubal 1952-Olympisch Stadion Amsterdam.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    jubalMoreImage: file(relativePath: { eq: "VVAL.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default class IndexPage extends React.Component<IndexPageProps, {}> {
  private openContactFormModal(formUrl: string) {
    return (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      window.open(
        formUrl,
        'popup',
        'width=600,height=600,scrollbars=no,resizable=no'
      );
    }
  }
  public render() {
    const {
      site,
      allProgramsdataJson,
      flitsenBannerImage,
      jubalHistoryImage,
      jubalMoreImage
    } = this.props.data;
    const {
      tagline,
    } = site.siteMetadata;

    return (
      <Layout location={this.props.location}>
        <SEO model={{}} />
        <header className={`${styles.mainHeader} ${styles.headerImage} d-flex flex-column justify-content-center`}>
          <SocialIcons
            isHorizontal={false}
            className="my-auto ml-auto mr-2 mr-sm-4 pt-5 pr-1 pr-sm-0"
          />
          <div className="p-absolute mx-auto mb-3 md-lg-4 mb-xl-5">
            <ArrowIcon isPointingDown={true} anchorLinkTo="/#intro" />
          </div>
        </header>

        <section id="intro" className={`bg-light px-3`}>
          <h1 className="mb-4 text-center">{tagline}</h1>
          <div className="container">
            <h4 className="w-75 mx-auto align-self-center text-center">
              <i>
                Al meer dan een eeuw garant voor spektakel, door haar continue vernieuwingsdrang altijd actueel en een garantie om het publiek op vele manieren te boeien en te verbazen.
              </i>
            </h4>
            <h3 className="mt-5 mb-0 text-center text-uppercase text-primary">
              <AnchorLink className="deco-none" stripHash={true} to="/#our-story" title="Ons verhaal">
                -&nbsp;&nbsp;Sinds 1911&nbsp;&nbsp;-
              </AnchorLink>
            </h3>
          </div>
        </section>

        <section id="news" className="bg-white">
          <h1 className="mb-4 text-center">Nieuws</h1>
          <div className="container">
            <div className="row mb-5">
              <div className="col-12 text-center">
                <h4 className="text-center text-muted mb-5">Het laatste nieuws van Jubal Dordrecht.</h4>
                <Link to="/flitsen" className="text-muted">
                  <Img
                    className="my-3 mt-lg-5 mt-xl-4 shadow"
                    fluid={flitsenBannerImage.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="Jubal Flitsen"
                  />
                </Link>
              </div>
            </div>
            <div className="mb-5 mt-4">
              <div className="text-center">
                <FacebookPostList limit={6} />
              </div>
            </div>
            <div className="col-12 text-center pt-5">
              <h5 className="section-subheading">
                Altijd op de hoogte van het laatste nieuws?
              </h5>
              <a
                className="btn btn-primary btn-xl mt-3 mb-5 text-uppercase"
                href="https://landing.mailerlite.com/webforms/landing/r3l8q8"
                target="_blank"
              >
                <span>Inschrijven voor de Jubal Nieuwsflits</span>
              </a>
            </div>
            {/* Todo: enable news archive */}
            <div className="row mb-5 d-none">
              <div className="col-12 mt-4 px-3 text-center">
                <h5 className="section-subheading">
                  <Link to="/archive" className="text-muted">
                    Nieuwsarchief
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section id="events" className="bg-light">
          <h1 className="mb-4 text-center">Agenda</h1>
          <div className="container text-center">
            <div className="text">
              <EventsList limit={3} />
            </div>
          </div>
        </section>

        {/* Todo: implement generic section component <Section /> */}

        <section id="programs" className="bg-white px-4 px-sm-3">
          <h1 className="mb-4 text-center">Programma's</h1>
          <div className="px-2 container">
            <h4 className="text-center text-muted mb-5">Onze actuele programma's en onderdelen.</h4>
            <h5 className="text text-center w-75 mb-5 mx-auto">
              <i>
                Jubal Dordrecht biedt een diversiteit aan programma's en onderdelen. Van Music Kids tot het Jubal drum &amp; bugle corps en van indoor-unit tot theateract op maat. Altijd vernieuwend en een garantie om het publiek op vele manieren te boeien en te verbazen.
              </i>
            </h5>
            <p className="text-center mb-5">
              Klik op de logo's of scroll verder om meer te weten te komen:
            </p>
            <div className={`d-flex flex-wrap justify-content-center mx-auto my-5 ${styles.programLogos}`}>
              {allProgramsdataJson.edges.map(({node}) => node).filter(x => x.featured).map(program => (
                <AnchorLink key={program.id} className="my-4" stripHash={true} to={`/#${program.anchor}`} title={program.name}>
                  <img src={program.logo.childImageSharp.fluid.src} />
                </AnchorLink>
              ))}
            </div>
            <div className="border-top border-primary mx-auto w-50"/>
            <div className={`d-flex flex-wrap justify-content-center mx-auto my-5 ${styles.programLogos}`}>
              {allProgramsdataJson.edges.map(({node}) => node).filter(x => !x.featured).map(program => (
                <AnchorLink key={program.id} className="my-4" stripHash={true} to={`/#${program.anchor}`} title={program.name}>
                  <img src={program.logo.childImageSharp.fluid.src} />
                </AnchorLink>
              ))}
            </div>
          </div>
        </section>

        <DisplaySection />

        {allProgramsdataJson.edges
          .map(({node}) => node)
          .filter(x => x.listed)
          .slice(0,-1)
          .map(program => (
          <section key={program.id} id={program.anchor} className="px-4 px-sm-3">
            <div className="px-2 container">
              <div className={`row mx-0`}>
                <div className="col-12 col-xl-6 pl-0 pr-0 pr-xl-3">
                  <div className={`text`}>
                    <h2>{program.name}</h2>
                    {program.description.map((paragraph, i) => (
                      <p key={`${program.id}-p-${i}`}>{paragraph}</p>
                    ))}
                  </div>
                </div>
                {(program.image !== null) ? (
                <div className="col-12 col-xl-6 pl-0 pr-0 pl-xl-3">
                  <Img
                    className="mt-3 mt-lg-5 mt-xl-4 shadow"
                    fluid={program.image.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt={program.name}
                  />
                </div>
                ) : <div />}
              </div>
            </div>
          </section>
        ))}

      <section id="theater-and-more" className="bg-white px-4 px-sm-3">
        <div className="px-2 container">
          <h2 className="text">Theater en meer</h2>
          <div className={`text ${styles.columnLayout}`}>
            <p>Naast traditionele optredens als straatparades, concerten en shows is Jubal ook regelmatig op andere manieren te zien en te horen geweest. Door de jaren heen heeft Jubal diverse theatershows gedaan en in de wintermaanden zijn de onderdelen van Jubal actief in het indoor circuit met een winter guard, indoor winds en indoor percussion. Rond de kerstperiode is Jubal in een andere samenstelling onder de noemer ‘Jubal All-Star X-Mas Band’ op diverse kerstmarkten in Nederland en Duitsland te zien en te horen met een aansprekend jazzy kerstrepertoire om het 'kerstwinkelende' publiek te vermaken.</p>
            <p>Ook in de media is Jubal een graag geziene en gehoorde gast. Al in 1936 trad Jubal op in radioprogramma’s van de NCRV en de VARA. Later, in de jaren zeventig, kreeg Jubal landelijke bekendheid door haar pauze-optredens voor de Minivoetbalshow. In de jaren daarna was Jubal regelmatig te zien en/of te horen in tv-shows in binnen- en buitenland. Recentelijk zijn de optredens in Boedapest bij spectaculaire concerten van de Hongaarse artiest Havasi en medewerking aan concerten van Vrienden van Amstel Live.</p>
          </div>
          <Img
            className="mt-5 shadow"
            fluid={jubalMoreImage.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Vrienden Van Amstel Live"
          />
        </div>

        {/* Todo: determine what to do with this part  */}
        <h5 className="text-center mt-5 d-none">Kortom:</h5>
        <h3 className="text-primary text-center d-none">
          <i>
            Jubal Dordrecht staat al meer dan een eeuw garant voor spektakel.
            <br/>Door haar continue vernieuwingsdrang altijd actueel
            <br/>en een garantie om het publiek op vele
            <br/>manieren te boeien en te verbazen.
          </i>
        </h3>

        <div className="text-center mt-5 d-none">
          <a className="btn btn-primary btn-xl m-2 text-uppercase js-scroll-trigger" href="#events">Maak kennis</a>
          <a className="btn btn-primary btn-xl m-2 text-uppercase js-scroll-trigger" href="#contact">Word lid</a>
          <div className="btn-group">
            <a href="mailto:bestuur@jubal.org?subject=Aanvraag via website" className="btn btn-primary btn-xl my-2 ml-2 text-uppercase js-scroll-trigger">Boek ons</a>
          </div>
        </div>
      </section>


        <section id="our-story" className={`bg-light px-3`}>
          <h1 className="mb-4 text-center">Ons verhaal</h1>
          <div className="container">
            <h4 className="text-center text-muted mb-5">Maak kennis met een rijke historie.</h4>
            <div className={`text`}>
              <p>Het Dordtse topkorps Jubal is opgericht in 1911 en is begonnen als een club met fluitjes en een aantal trommeltjes, ter ondersteuning van een feestavond van jongensvereniging Timotheüs, een onderafdeling van de Dordtse CJMV.</p>
              <p>Duizenden mensen zijn sinds dat jaar lid geweest van deze wervelende en zichzelf continu vernieuwende muziekvereniging, die inmiddels over een groot gedeelte van de aardbol heeft opgetreden. Van Vlissingen tot het Franse Nice, van Frankfurt tot het Noorse Hamar en van Dubbeldam tot Chicago. Vele successen, ontelbare prijzen, lovende recensies: meer dan 100 jaar Jubal heeft de club geen windeieren gelegd. Maar wat misschien nog veel belangrijker is, zijn de vriendschappen, het plezier, de ervaringen, the way of life, dingen die de Juballers allemaal hebben meegekregen.</p>
            </div>
            <Img
              className="my-5 shadow"
              fluid={jubalHistoryImage.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="Jubal 1952 - Olympisch Stadion Amsterdam"
            />
            <div className={`text ${styles.columnLayout}`}>
              <p>Het huidige Drum & Bugle Corps is in 2021 al honderdtien jaar lang méér dan een muziekvereniging. Het is een club die bindt en boeit, ontroert en in vervoering brengt. Jubal is een korps dat in binnen- en buitenland bekendheid geniet met aansprekende showprogramma’s. Dat heeft niet alleen geleid tot een overvolle prijzenkast, maar nog belangrijker, tot de status van ware publieksfavoriet.</p>
              <p>De club is met nieuwe initiatieven nog altijd een voorbeeld voor veel andere muziekverenigingen en heeft een geschiedenis die leest als een jongensboek. Jubal heeft in meer dan 100 jaar tijd grote successen bereikt, maar ook fikse tegenslagen moeten overwinnen. Zelfs toen al het materiaal in de Tweede Wereldoorlog door de Duitsers in beslag werd genomen, gaf Jubal zich niet gewonnen en werd er ‘ondergronds’ door gerepeteerd en werd er in ondergrondse tromfabriek ‘De Geitevel’ nieuw percussie-materiaal gefabriceerd en daarmee verscheen het direct na de bevrijding alweer op straat. Die geschiedenis geeft het het vertrouwen dat ook de meest recente tegenslag, de gevolgen van het COVID-19-virus, overwonnen zal worden.</p>
              <p>Door de geschiedenis heen toont Jubal het doorzettingsvermogen en de continue vernieuwingsdrang van een fanatieke groep amateur-muzikanten.</p>
            </div>
          </div>
        </section>

        <section id="contact" className={`bg-white m-0 mx-auto pb-0 pt-lg-0 row ${styles.contactSection}`}>
          <h1 className="d-lg-none col-12 text-center mb-4">Contact</h1>
          <h4 className="d-lg-none col-12 text-center text-muted mb-4">Wanneer de interesse is gewekt.</h4>
          <div className="col-12 col-lg-6 py-3 px-4">
            <div className="container mb-4 mb-lg-0 pb-4 pb-lg-0 pt-xl-3 text-center text-lg-left">
              <h1 className="d-none d-lg-block pt-3">Contact</h1>
              <p className="">We komen graag met je in contact! Je kunt bij ons terecht voor optredens en entertainment op maat, maar ook voor informatie over lidmaatschap van de vereniging of verhuur van onze faciliteiten.</p>
              <a
                className="btn btn-primary btn-xl mt-4 mt-lg-0 mb-1 mx-auto mx-lg-0 text-uppercase"
                href={site.siteMetadata.contactFormUrl}
                target="popup"
                onClick={this.openContactFormModal(site.siteMetadata.contactFormUrl)}
              >
                <span>Contact opnemen</span>
              </a>
            </div>
          </div>
          <div className={`col-12 col-lg-6 pl-0 map d-flex justify-content-start align-items-end align-items-lg-center`}>
            <div className="ml-0 px-0 px-xl-5 py-auto h-100 d-flex justify-content-center align-items-center address-route text-center text-light">
              <div className="mx-5">
                <h3>Bezoek het JMC</h3>
                <h5 className="mb-3">
                  Jubal Muziek Centrum
                  <br /><br />Jan Ligthartlaan 1
                  <br />3312 KD Dordrecht
                </h5>
                <a
                  className="btn btn-primary btn-xl mb-1 text-uppercase"
                  href="https://www.google.com/maps/place/Jubal+Muziek+Centrum/@51.8005105,4.6804554,17z/data=!3m1!4b1!4m5!3m4!1s0x47c428d88e15cabf:0x1377c385057efe6f!8m2!3d51.8005105!4d4.6826441"
                  target="_blank"
                >
                  <span>Plan je route</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
